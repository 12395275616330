<template>
  <v-app>
    <v-row justify="center">
      <v-col cols="10" sm="8" md="6" lg="4" class="my-auto">
        <base-material-card color="primary">
          <template v-slot:heading>
            <div class="display-2 font-weight-light">Forgot Password</div>
          </template>
          <v-container fluid>
            <v-text-field
              v-model="data.email"
              name="email"
              :rules="[rules.required]"
              label="E-mail"
            ></v-text-field>

            <v-col class="text-right">
              <v-btn
                class="mr-4 mt-4"
                @click="sendResetPasswordMail(data)"
                color="primary"
                :loading="is_sending"
              >
                {{ $t("send_reset_password_mail") }}
              </v-btn>
            </v-col>
          </v-container>
        </base-material-card>
      </v-col>
    </v-row>
  </v-app>
</template>

<script>
import { validationRules } from "@/mixins/validationRules";
export default {
  mixins: [validationRules],
  name: "ForgotPassword",
  data() {
    return {
      data: {},
      valid: false,
      is_sending: false,
    };
  },

  computed: {
    isAuthenticated() {
      return this.$store.getters.isAuthenticated;
    },
  },

  methods: {
    async sendResetPasswordMail(data) {
      data.deep_link =
        location.origin +
        this.$router.resolve({
          name: "reset.password",
          params: { token: "put_your_token_here" },
        }).href;
      try {
        this.is_sending = true;
        await this.$store.dispatch("sendResetPasswordMail", data);
        this.$store.dispatch(
          "alerts/success",
          this.$t("mail_sended_successfully")
        );
        this.is_sending = false;
      } catch (error) {
        this.is_sending = false;
        console.log(error);
        this.$store.dispatch("alerts/error", error?.response?.data?.message);
      }
      this.is_sending = false;
    },
  },
};
</script>
